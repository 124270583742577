import {
	Address,
	AddressType,
	Beneficiary,
	BeneficiaryParty,
	Coverage,
	InsuredPerson,
	InsuredPersonRole,
	NdpAddress,
	NdpInsuredPerson,
	PensionBeneficiary,
	Rider,
} from "../../../models";
import cs from "../../translations/cs.json";

export type CommonPersonalDetails = {
	titlePrefix?: string;
	titleSuffix?: string;
	firstName?: string;
	lastName?: string;
	birthDate?: string | Date;
	birthNumber?: string;
	roleDescription?: string;
	hasRiders: boolean;
	isChild: boolean;
};

export type CommonLifeContractPerson = {
	personalDetails: CommonPersonalDetails;
	coverages: CommonCoverage[];
	beneficiaries?: CommonBeneficiaryType[];
};

export type CommonCoverage = {
	id: string;
	name: string;
	description: string;
	isMain: boolean;
	validFrom?: string | Date;
	validTo?: string | Date;
	ammount: number;
	premium?: number;
	documentPath?: string;
};

export type CommonBeneficiaryType = {
	firstName?: string;
	lastName?: string;
	share?: number;
	birthDate?: string | Date;
	contactAdress?: Address;
	isBank?: boolean;
	relationship?: string;
};

export const mapNdpAdressToAdress = (adress: NdpAddress): Address => {
	return {
		note: null,
		houseNumber: adress.houseNumber,
		country: adress.country,
		street: adress.street,
		town: adress.city,
		zip: adress.zipCode as string,
		type: AddressType.Unknown,
	};
};

export const mapFromV1RiderToCommonCoverage = (
	rider: Rider
): CommonCoverage => {
	return {
		id: rider.riderId,
		name: rider.name,
		ammount: rider.amount,
		description: rider.description,
		isMain: rider.isMainRider,
		validFrom: rider.validFrom ?? undefined,
		validTo: rider.validTo ?? undefined,
		documentPath: rider.filePath ?? undefined,
	};
};

export const mapFromV2CoverageToCommonCoverage = (
	coverage: Coverage
): CommonCoverage => {
	return {
		id: coverage.code as string,
		ammount: coverage.sumAssured,
		description: cs.global.notInNDP,
		isMain: coverage.isMain,
		name:
			coverage.name && coverage.name !== ""
				? coverage.name
				: cs.global.notInNDP,
		documentPath: undefined,
		validFrom: coverage.dateStart ?? undefined,
		validTo: coverage.dateEnd ?? undefined,
		premium: coverage.premium,
	};
};

export const mapFromV1Beneficiary = (
	beneficiary: PensionBeneficiary | Beneficiary
): CommonBeneficiaryType => {
	return {
		firstName: beneficiary.firstName ?? undefined,
		lastName: beneficiary.lastName ?? undefined,
		birthDate: beneficiary.dateOfBirth ?? undefined,
		isBank: "isBank" in beneficiary ? beneficiary.isBank : false,
		share: beneficiary.ratio ?? undefined,
		relationship: beneficiary.relationship
			? cs.relationship[beneficiary.relationship]
			: undefined,
		contactAdress: {
			country: beneficiary.address?.country,
			houseNumber: beneficiary.address?.houseNumber,
			street: beneficiary.address?.street,
			town: beneficiary.address?.town,
			zip: beneficiary.address?.zip as string,
			note: null,
			type: AddressType.Delivery,
		},
	};
};

export const mapFromV2BeneficiaryParty = (
	beneficiaryParty: BeneficiaryParty
): CommonBeneficiaryType => {
	return {
		firstName: beneficiaryParty.beneficiary.person?.firstName ?? undefined,
		lastName: beneficiaryParty.beneficiary.person?.lastName ?? undefined,
		birthDate: beneficiaryParty.beneficiary.person?.birthDate ?? undefined,
		isBank: beneficiaryParty.isBank,
		share: beneficiaryParty.beneficiaryShare,
		relationship:
			cs.relationship[
				beneficiaryParty.relationship as keyof typeof cs.relationship
			] ??
			(beneficiaryParty.relationship
				? beneficiaryParty.relationship
				: undefined),
		contactAdress: beneficiaryParty.beneficiary.contactAddress
			? mapNdpAdressToAdress(beneficiaryParty.beneficiary.contactAddress)
			: undefined,
	};
};

export const mapCommonPeronalDetailFromInsuredPerson = (
	insuredPerson: InsuredPerson
): CommonPersonalDetails => {
	return {
		firstName: insuredPerson.firstName ?? undefined,
		lastName: insuredPerson.lastName ?? undefined,
		hasRiders: insuredPerson.role !== InsuredPersonRole.PolicyHolder,
		isChild: insuredPerson.role === InsuredPersonRole.Children,
		roleDescription: cs.life.roles[insuredPerson.role],
		birthDate: insuredPerson.dateOfBirth ?? undefined,
		birthNumber: insuredPerson.birthNumber ?? undefined,
	};
};

export const mapCommonPersonalDetailFromNdpInsuredPerson = (
	insuredPerson: NdpInsuredPerson,
	isOwner?: boolean
): CommonPersonalDetails => {
	const roleDescription = (() => {
		if (!isOwner) return;
		return `${cs.life.global.owner} a 1.${cs.life.global.insuredPerson}`;
	})();
	return {
		titlePrefix: insuredPerson.person.titlePrefix ?? undefined,
		titleSuffix: insuredPerson.person.titleSuffix ?? undefined,
		firstName: insuredPerson.person.firstName ?? undefined,
		lastName: insuredPerson.person.lastName ?? undefined,
		hasRiders: insuredPerson.coverages.length > 0,
		isChild: !insuredPerson.person.isAdult,
		roleDescription: roleDescription,
		birthDate: insuredPerson.person.birthDate ?? undefined,
		birthNumber: insuredPerson.person.birthNumber ?? undefined,
	};
};
