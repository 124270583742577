import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Button, Stack } from "@mui/material";
import useIsDesktop from "desktop/useIsDesktop";
import { navigate } from "gatsby";
import { isNil } from "lodash";

import {
	GetLifeTransactionsResponse,
	GetPensionTransactionsResponse,
	ProductType,
} from "../../../models";
import { contractLink } from "../../functions";
import {
	isUnsupportedFufiContractTypeError,
	useTransactions,
} from "../../queryHooks";
import { SupportedContractType } from "../../types/contracts";
import { TransactionsListSkeleton } from "../DPS/Transactions/TransactionsList";
import TransactionsContainer from "../Transactions/TransactionsContainer";
import { HeadingS } from "../Typography";
import { TransactionsNotSupported } from "../ZivotniPojisteni/Tabs/components/Transactions/TransasctionComponent";

/**
 * Komponenta podporuje loading stav ktery se ukazuje, dokud nenacte transakce
 * k idcku. Transakce nezacne nacitat, dokud nedostane idcko
 * @param id
 * @param type
 * @constructor
 */

interface ITransactionsById {
	id: string;
	type: SupportedContractType;
	isLoading?: boolean;
	transactionMapper?: (
		data: GetPensionTransactionsResponse | GetLifeTransactionsResponse
	) => GetPensionTransactionsResponse | GetLifeTransactionsResponse;
}

const transactionLinkByType: Record<SupportedContractType, string> = {
	[ProductType.Cl]: "pohyby",
	[ProductType.Clf]: "pohyby",
	[ProductType.Uf]: "pohyby-platby",
	[ProductType.Pf]: "pohyby-platby",
};

const TransactionsById = ({
	id,
	type,
	isLoading = false,
	transactionMapper,
}: ITransactionsById) => {
	const { data, isSuccess, isError } = useTransactions({
		type: type,
		params: {
			contractId: id,
			count: 4,
		},
		options: {
			enabled: !!id,
			refetchOnWindowFocus: false,
			retry: (failureCount, error) => {
				if (failureCount >= 3) return false;
				return !isUnsupportedFufiContractTypeError(error, type);
			},
			throwOnError: (error) => {
				return !isUnsupportedFufiContractTypeError(error, type);
			},
			select: (data) => {
				if (!transactionMapper || isNil(data)) return data;
				return data.map((transaction) => transactionMapper(transaction));
			},
		},
	});

	const isDesktop = useIsDesktop();

	return (
		<Stack sx={{ gap: 4 }}>
			<HeadingS mb={0}>Poslední platby</HeadingS>
			{(() => {
				if (isError) return <TransactionsNotSupported />;
				if (isSuccess && !isLoading)
					return (
						<TransactionsContainer transactions={data} isIcon={isDesktop} />
					);
				return <TransactionsListSkeleton skeletonsCount={4} />;
			})()}
			<Button
				sx={{ width: "fit-content", pl: 0 }}
				disabled={!isSuccess}
				onClick={() =>
					navigate(contractLink(id, type, transactionLinkByType[type]))
				}
				variant="text"
				size="small"
				endIcon={<ArrowForwardIcon />}
			>
				Zobrazit všechny platby
			</Button>
		</Stack>
	);
};

export default TransactionsById;
