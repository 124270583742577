import { FauxLoggedInBar } from "../../components/AppBar/Bars";
import Attention from "../../components/AttentionBlock";
import ErrorContent from "../../components/ErrorPage/ErrorContent";
import { PhoneLink } from "../../components/Link/ContactLink";
import { SEO } from "../../components/SEO";
import cs from "../../translations/cs.json";
export const AppBar = (): JSX.Element => <FauxLoggedInBar />;

const PidError = (): JSX.Element => {
	return (
		<ErrorContent>
			<Attention severity="success" title={cs.pidError.header}>
				<p>{cs.pidError.oneMoreStep}</p>
				<p>{cs.pidError.accountOwnerConfirmation}</p>
				<p>
					<b>{cs.pidError.contactUs}</b>{" "}
					<PhoneLink phone="+420244090800">244 090 800</PhoneLink>
					{"."}
				</p>
				<p>{cs.pidError.availability}</p>
			</Attention>
		</ErrorContent>
	);
};

export default PidError;

export const Head = (): JSX.Element => (
	<SEO title="Uživatel nenalezen">
		<meta name="robots" content="noindex" />
	</SEO>
);
