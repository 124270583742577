import {
	Divider,
	IconButton,
	Paper,
	Skeleton,
	Stack,
	StackProps,
} from "@mui/material";
import Box from "@mui/material/Box";
import { isValidElement, ReactElement, ReactNode } from "react";

import InfoIcon from "../../components/Icons/Info";
import { useDrawer } from "../Drawer/context";
import HeaderWithDescription, {
	HeaderWithDescriptionProps,
} from "../HeaderWithDescription";
import { HeadingM } from "../Typography";

interface IInformationListHeader {
	title?: string;
	action?: ReactNode;
	infoDrawer?: ReactNode;
}

const InformationListHeader = ({
	title,
	action,
	infoDrawer,
}: IInformationListHeader) => {
	const { showDrawer, setDrawerContent } = useDrawer();

	return (
		<Stack
			sx={{
				flexDirection: "row",
				justifyContent: "space-between",
				alignItems: "center",
			}}
		>
			<Stack sx={{ flexDirection: "row", gap: 2 }}>
				{title && (
					<HeadingM
						withoutScale
						sx={{
							display: infoDrawer ? "block" : "inline",
							verticalAlign: "middle",
							margin: "0px !important",
							whiteSpace: "nowrap",
						}}
					>
						{title}
					</HeadingM>
				)}
				{infoDrawer && (
					<>
						&nbsp;
						<IconButton
							data-test="info-icon"
							onClick={() => {
								showDrawer();
								setDrawerContent(infoDrawer);
							}}
							sx={{ p: 1 }}
							size="small"
						>
							<InfoIcon
								color="text"
								sx={{ width: "1.8rem", height: "1.8rem" }}
							/>
						</IconButton>
					</>
				)}
			</Stack>
			{action}
		</Stack>
	);
};

export type InformationItem = HeaderWithDescriptionProps | ReactElement | false;

export interface InformationListProps extends StackProps {
	title?: string;
	infoIconDrawer?: ReactElement;
	action?: ReactElement;
	information: InformationItem[];
	isLoading?: boolean;
	secondaryAction?: ReactElement;
	skeletonCount?: number;
}

const InformationList = ({
	title,
	infoIconDrawer,
	action,
	secondaryAction,
	information,
	isLoading,
	sx,
	skeletonCount,
	...rest
}: InformationListProps) => {
	const showTitle = title || action;
	return (
		<Stack
			{...rest}
			sx={{ gap: 4, height: { md: "auto", xxs: "100%" }, ...sx }}
		>
			{showTitle && !isLoading && (
				<InformationListHeader
					title={title}
					action={action}
					infoDrawer={infoIconDrawer}
				/>
			)}
			{showTitle && isLoading && <Skeleton width={250} sx={{ mb: 4 }} />}
			<Paper
				sx={{
					height: "100%",
					p: { md: 5, xxs: 4 },
				}}
			>
				<Stack spacing={3} divider={<Divider />}>
					{!isLoading
						? information
								.filter((i) => !!i)
								.map((information, i) =>
									isValidElement(information) ? (
										information
									) : (
										<HeaderWithDescription
											key={i}
											{...(information as HeaderWithDescriptionProps)}
										/>
									)
								)
						: [...Array(skeletonCount ?? 3)].map((_, i) => (
								<HeaderWithDescription
									key={i}
									title={<Skeleton width="100%" />}
									description={<Skeleton width="100%" />}
								/>
						  ))}
				</Stack>
				<Box sx={{ alignItems: "end" }}>{secondaryAction}</Box>
			</Paper>
		</Stack>
	);
};

export default InformationList;

export interface InformationListSkeletonProps extends StackProps {
	title?: string;
	length: number;
	action?: ReactElement;
}

export function InformationListSkeleton({
	length,
	title,
	action,
	sx,
}: InformationListSkeletonProps): JSX.Element {
	return (
		<InformationList
			title={title}
			information={new Array(length).fill({
				title: <Skeleton />,
				description: <Skeleton width={"100%"} />,
			})}
			action={action}
			sx={sx}
		/>
	);
}
