/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp.General
 * GeneralAPIs
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BankIdValidateLoginRequest
 */
export interface BankIdValidateLoginRequest {
    /**
     * 
     * @type {string}
     * @memberof BankIdValidateLoginRequest
     */
    cipher: string;
    /**
     * 
     * @type {string}
     * @memberof BankIdValidateLoginRequest
     */
    tag: string;
    /**
     * 
     * @type {string}
     * @memberof BankIdValidateLoginRequest
     */
    nonce: string;
}

/**
 * Check if a given object implements the BankIdValidateLoginRequest interface.
 */
export function instanceOfBankIdValidateLoginRequest(value: object): value is BankIdValidateLoginRequest {
    if (!('cipher' in value) || value['cipher'] === undefined) return false;
    if (!('tag' in value) || value['tag'] === undefined) return false;
    if (!('nonce' in value) || value['nonce'] === undefined) return false;
    return true;
}

export function BankIdValidateLoginRequestFromJSON(json: any): BankIdValidateLoginRequest {
    return BankIdValidateLoginRequestFromJSONTyped(json, false);
}

export function BankIdValidateLoginRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): BankIdValidateLoginRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'cipher': json['cipher'],
        'tag': json['tag'],
        'nonce': json['nonce'],
    };
}

export function BankIdValidateLoginRequestToJSON(value?: BankIdValidateLoginRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'cipher': value['cipher'],
        'tag': value['tag'],
        'nonce': value['nonce'],
    };
}

