import { Button, ButtonProps as MUIButtonProps, Stack } from "@mui/material";
import { ReactNode, useState } from "react";

import { PensionPhaseStatus } from "../../../models";
import { useContractId } from "../../context/ContractIDContext";
import { useContractById } from "../../queryHooks";
import TriggerButton from "../Link/TriggerButton";
import { BodyTextM } from "../Typography";
import SimpleDrawer from "./SimpleDrawer";

export const NotInMVPDrawerContent = () => {
	return (
		<>
			Je nám líto, ale momentálně nelze provést změnu přes Portál. Na přidání
			této změny usilovně pracujeme.
		</>
	);
};

interface NotInMVPProps {
	onClick: () => void;
}

function NotInMVP({ onClick }: NotInMVPProps) {
	const [isOpen, setIsOpen] = useState(false);
	const id = useContractId();
	const { data } = useContractById({
		id: id,
		options: { refetchOnMount: false },
	});
	return (
		<>
			{data?.pensionPhaseStatus === PensionPhaseStatus.Saving && (
				<Button
					variant="action"
					isHorizontal
					sx={{
						alignSelf: { md: "flex-start" },
					}}
					onClick={() => {
						onClick();
						setIsOpen(true);
					}}
				>
					<BodyTextM color="primary.main">Změnit</BodyTextM>
				</Button>
			)}
			<SimpleDrawer
				onOpen={() => setIsOpen(true)}
				onClose={() => setIsOpen(false)}
				open={isOpen}
			>
				<Stack spacing={5}>
					<BodyTextM>
						<NotInMVPDrawerContent />
					</BodyTextM>
					<TriggerButton variant="action" onClick={() => setIsOpen(false)}>
						Rozumím
					</TriggerButton>
				</Stack>
			</SimpleDrawer>
		</>
	);
}

type ButtonProps = {
	text: string;
	content?: ReactNode;
	onClick?: () => void;
} & MUIButtonProps;

export function NotInMVPButton({
	text,
	content,
	sx,
	onClick,
	...rest
}: ButtonProps): JSX.Element {
	const [isOpen, setIsOpen] = useState(false);
	return (
		<>
			<Button
				{...rest}
				variant="contained"
				sx={{
					alignSelf: { md: "flex-start" },
					width: { md: "auto", xxs: "100%" },
					...sx,
				}}
				onClick={() => {
					onClick?.();
					setIsOpen(true);
				}}
			>
				{text}
			</Button>
			<SimpleDrawer onClose={() => setIsOpen(false)} open={isOpen}>
				<Stack spacing={5}>
					<BodyTextM>{content ?? <NotInMVPDrawerContent />}</BodyTextM>
					<TriggerButton
						onClick={() => setIsOpen(false)}
						sx={{ alignSelf: "flex-end" }}
					>
						Rozumím
					</TriggerButton>
				</Stack>
			</SimpleDrawer>
		</>
	);
}

export default NotInMVP;
