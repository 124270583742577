import { Paper, Stack } from "@mui/material";
import useIsDesktop from "desktop/useIsDesktop";

import {
	GetLifeDetailV2Response,
	GetLifeTransactionsResponse,
	PensionPhaseStatus,
	ProductType,
} from "../../../models";
import { useContractById, useLifeDetail } from "../../queryHooks";
import cs from "../../translations/cs.json";
import { SupportedContractType } from "../../types/contracts";
import { DataWithStatus, mapToDataWithStatus } from "../../types/global";
import { TransactionsListSkeleton } from "../DPS/Transactions/TransactionsList";
import { DebtCtaPay } from "../Link/CTA/PayById";
import {
	DebtInformation,
	useDebtInformation,
} from "../Link/CTA/useDebtInformation";
import { mapLifeTransactionStatusToString } from "../ZivotniPojisteni/Tabs/components/Transactions/Table";
import ContractCard, { ContractCardSkeletonHeader } from "./ContractCard";
import { ContractHeaderWithFufiData } from "./ContractCard/ContractCardHeader";
import SmartActionsById from "./SmartAction";
import TransactionsById from "./Transaction";
type ContractProps = DataWithStatus<{
	contractId: string;
	type: SupportedContractType;
	hasSmartActions: boolean;
	debtInformation: DebtInformation | null;
	transactionsComponent: JSX.Element;
	headerComponent: JSX.Element;
}>;

export const Contract = ({ data, isSuccess }: ContractProps): JSX.Element => {
	const isDesktop = useIsDesktop();

	const getTransactions = () => {
		if (!isSuccess) return <TransactionsListSkeleton skeletonsCount={4} />;
		return data.transactionsComponent;
	};

	const getHeader = () => {
		if (!isDesktop) return null;
		if (!isSuccess) return <ContractCardSkeletonHeader />;
		return data.headerComponent;
	};

	return (
		<Paper elevation={isDesktop ? 4 : 0} sx={{ p: { md: 5 } }}>
			<Stack spacing={5} sx={{ px: { md: 0, xxs: 4 } }}>
				{getHeader()}
				{isSuccess && data.hasSmartActions && (
					<SmartActionsById id={data.contractId} type={data.type} />
				)}
				{data?.debtInformation?.isSuccess && data.debtInformation.data && (
					<DebtCtaPay debtStatus={data.debtInformation.data} />
				)}
				{getTransactions()}
			</Stack>
		</Paper>
	);
};

interface IContractWithData {
	contractId: string;
}

const DPSContractCard = ({ contractId }: IContractWithData) => {
	const { data, isSuccess } = useContractById({ id: contractId });

	return (
		<Contract
			{...mapToDataWithStatus(
				{
					contractId,
					type: ProductType.Uf,
					debtInformation: null,
					hasSmartActions:
						!isSuccess || data.pensionPhaseStatus !== PensionPhaseStatus.Payout,
					headerComponent: (
						<ContractCard
							contractId={contractId}
							position="preview"
							type={ProductType.Uf}
						/>
					),
					transactionsComponent: (
						<TransactionsById id={contractId} type={ProductType.Uf} />
					),
				},
				isSuccess
			)}
		/>
	);
};

const PPContractCard = ({ contractId }: IContractWithData) => {
	const { data, isSuccess } = useContractById({ id: contractId });

	return (
		<Contract
			{...mapToDataWithStatus(
				{
					contractId,
					type: ProductType.Pf,
					debtInformation: null,
					hasSmartActions:
						!isSuccess || data.pensionPhaseStatus !== PensionPhaseStatus.Payout,
					headerComponent: (
						<ContractCard
							contractId={contractId}
							position="preview"
							type={ProductType.Pf}
						/>
					),
					transactionsComponent: (
						<TransactionsById id={contractId} type={ProductType.Pf} />
					),
				},
				isSuccess
			)}
		/>
	);
};

const LifeContractCard = ({ contractId }: IContractWithData) => {
	const debtInformation = useDebtInformation();
	return (
		<Contract
			isSuccess
			data={{
				contractId,
				type: ProductType.Cl,
				debtInformation,
				hasSmartActions: true,
				headerComponent: (
					<ContractCard
						contractId={contractId}
						position="preview"
						type={ProductType.Cl}
					/>
				),
				transactionsComponent: (
					<TransactionsById
						id={contractId}
						type={ProductType.Cl}
						transactionMapper={(transaction) => ({
							...transaction,
							date: (transaction as GetLifeTransactionsResponse).isPaid
								? transaction.date
								: null,
						})}
					/>
				),
			}}
		/>
	);
};

type LifeTransactionMapper = (
	transaction: GetLifeTransactionsResponse
) => GetLifeTransactionsResponse;

const FufiContractCard = ({ contractId }: IContractWithData) => {
	const { data, isSuccess } = useLifeDetail<GetLifeDetailV2Response>({
		id: contractId,
		contractType: ProductType.Clf,
	});

	const mapper: LifeTransactionMapper = (transaction) => ({
		...transaction,
		title: `${data ? cs.frequencies[data?.paymentFrequency] : ""} ${
			cs.global.dueInsurance
		} - ${mapLifeTransactionStatusToString(transaction.status)}`,
		date: transaction.isPaid ? transaction.date : null,
	});

	return (
		<Contract
			isSuccess
			data={{
				contractId,
				type: ProductType.Clf,
				hasSmartActions: true,
				debtInformation: null,
				headerComponent: <ContractHeaderWithFufiData contractId={contractId} />,
				transactionsComponent: (
					<TransactionsById
						isLoading={!isSuccess}
						id={contractId}
						type={ProductType.Clf}
						transactionMapper={mapper}
					/>
				),
			}}
		/>
	);
};

export const contractByIdFromType = {
	[ProductType.Uf]: DPSContractCard,
	[ProductType.Pf]: PPContractCard,
	[ProductType.Cl]: LifeContractCard,
	[ProductType.Clf]: FufiContractCard,
};
