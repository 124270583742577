import { motion } from "framer-motion";
import Attention from ".";
import { useEffect, useRef } from "react";
import cs from "../../translations/cs.json";

const ProcessingBanner = ({isError}: {isError: boolean}) => {
	const attentionRef = useRef<HTMLDivElement>(null); 
	useEffect(() => {
		if (attentionRef.current) {
		  attentionRef.current.scrollIntoView({ behavior: 'smooth' });
		}
	  }, [attentionRef]);
	
		return (
			<motion.div
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{ opacity: 0}}
				transition={{ duration: 1 }}
				ref={attentionRef}
				>
				{isError ? (
					<Attention severity="error">
						{cs.global.unsuccesfulNotification}
					</Attention>
				) : (
					<Attention severity="success">
						{cs.global.successNotification}
					</Attention>
				)}
			</motion.div>	
		)
	}

export default ProcessingBanner;