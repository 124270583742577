import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { Skeleton, Stack } from "@mui/material";
import useIsDesktop from "desktop/useIsDesktop";
import { StaticImage } from "gatsby-plugin-image";
import { Button } from "gatsby-theme-material-ui";
import { useState } from "react";

import {
	GetDPSDetailResponse,
	GetLifeDetailResponse,
	GetPPDetailResponse,
	InsuredPersonRole,
	PensionPhaseStatus,
	ProductType,
} from "../../../models";
import { useContractId } from "../../context/ContractIDContext";
import { useContractTypeContext } from "../../context/ContractTypeContext";
import { dateTimeFormat } from "../../functions/dates";
import { useCountry } from "../../hooks/useCountry";
import { useHasCurrentContactsChange } from "../../hooks/useHasCurrentContactsChange";
import { useContractById, useLifeDetail } from "../../queryHooks";
import cs from "../../translations/cs.json";
import AddressTemplate from "../AddressBox/AddressTemplate";
import Attention from "../AttentionBlock";
import ChangeAddressDrawer from "../Drawer/ChangeAddressDrawer";
import InfoIconDrawer from "../Drawer/InfoIconDrawer";
import Modal from "../Drawer/Modal";
import NotInMVP from "../Drawer/NotInMVP";
import InformationList from "../InformationList";
import { InternalButtonLink } from "../Link";
import { BodyTextM, HeadingL } from "../Typography";

interface ClientSharedProps {
	person: GetDPSDetailResponse | GetPPDetailResponse;
	isSuccess: boolean;
	isLoading: boolean;
}

function ClientShared({
	person,
	isSuccess,
	isLoading,
}: ClientSharedProps): JSX.Element {
	const isDesktop = useIsDesktop();
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);
	const [formWasSend, setFormWasSend] = useState<boolean>(false);
	const { contractType } = useContractTypeContext();
	const { data: hasContactChange, isSuccess: contactChangeSuccess } =
		useHasCurrentContactsChange();

	const ai = useAppInsightsContext();

	const handleClick = (changeText: string) => {
		ai.trackEvent({
			name: `[Change] ${changeText}`,
			properties: {
				type: "not in MVP",
			},
		});
	};

	const {
		clientInfo: {
			firstName,
			lastName,
			birthNumber,
			dateOfBirth,
			degreeBefore,
			degreeAfter,
			citizenship,
		},
		citizenship: citizenshipLife,
		permanentAddress,
		contactAddress,
		contact,
		isContactAddressChangeInProgress,
	} = isSuccess
		? person
		: {
				clientInfo: {
					firstName: undefined,
					lastName: undefined,
					birthNumber: undefined,
					dateOfBirth: undefined,
					degreeBefore: undefined,
					degreeAfter: undefined,
					citizenship: undefined,
				},
				citizenship: undefined,
				permanentAddress: undefined,
				contactAddress: undefined,
				contact: {
					phone1: undefined,
					phone2: undefined,
					mobile: undefined,
					email: undefined,
				},
				isContactAddressChangeInProgress: undefined,
		  };

	const citizenshipLong = useCountry(
		citizenship || citizenshipLife
	)?.country_name_cz_long;

	const contactInfoItems = [
		contact?.phone1 && {
			title: cs.global.phone,
			description: contact.phone1,
		},
		contact?.phone2 && {
			title: `${cs.global.phone} 2`,
			description: contact.phone2,
		},
		contact?.mobile && {
			title: cs.global.mobilePhone,
			description: contact.mobile,
		},
		contact?.email && {
			title: cs.global.email,
			description: contact.email,
		},
	].filter((item) => typeof item === "object" && item !== null);

	const id = useContractId();
	const { data } = useContractById({
		id: id,
		options: { refetchOnMount: false },
	});

	const { data: life } = useLifeDetail<GetLifeDetailResponse>({
		id,
		contractType: ProductType.Cl,
		options: {
			enabled: contractType === ProductType.Cl,
		},
	});
	const policyHolder = life?.insuredPersons.find(
		(p) =>
			InsuredPersonRole.PolicyHolder === p.role ||
			InsuredPersonRole.PolicyHolderAndFirst === p.role ||
			InsuredPersonRole.PolicyHolderAndSecond === p.role
	);
	const isNotLifePolicyHolder =
		policyHolder?.birthNumber !== birthNumber &&
		contractType === ProductType.Cl;

	return (
		<>
			<Stack
				gap={{ md: 5, xxs: 0 }}
				sx={{
					flexDirection: { md: "row" },
					alignItems: { md: "flex-end" },
				}}
			>
				<Stack
					spacing={5}
					sx={{
						width: { md: "50%" },
						mr: { md: 4 },
					}}
				>
					<HeadingL withoutScale>Osobní informace</HeadingL>
					{data?.pensionPhaseStatus === PensionPhaseStatus.Payout && (
						<>
							<Attention severity="warning">
								{cs.profile.person.changeContactInfoTerminatedContract}
							</Attention>
						</>
					)}
					<InformationList
						isLoading={isLoading}
						title="Osobní údaje"
						action={
							<NotInMVP
								onClick={() => {
									handleClick("Osobní údaje");
								}}
							/>
						}
						information={[
							{
								title: "Titul před jménem",
								description: degreeBefore ?? "-",
							},
							{
								title: "Jméno",
								description:
									contractType === ProductType.Cl
										? firstName ?? "-"
										: firstName,
							},
							{
								title: "Příjmení",
								description:
									contractType === ProductType.Cl ? lastName ?? "-" : lastName,
							},
							{
								title: "Titul za jménem",
								description: degreeAfter ?? "-",
							},
							{
								title: "Datum narození",
								description:
									dateOfBirth && dateTimeFormat.format(new Date(dateOfBirth)),
							},
							{
								title: <>Rodné číslo (číslo&nbsp;pojištěnce)</>,
								description: birthNumber ?? "-",
							},
							{
								title: "Státní občanství",
								description: citizenshipLong ?? "-",
							},
						]}
						sx={{ mb: 5 }}
					/>
					{permanentAddress && (
						<InformationList
							isLoading={isLoading}
							title={cs.global.permanentResidence}
							action={
								<Button
									variant="action"
									onClick={() => {
										setIsDrawerOpen(true);
									}}
								>
									<BodyTextM>{cs.global.change}</BodyTextM>
								</Button>
							}
							information={[
								{
									title: "Adresa",
									description: <AddressTemplate address={permanentAddress} />,
								},
							]}
						/>
					)}

					<Modal
						open={isDrawerOpen}
						onClose={() => {
							setIsDrawerOpen(false);
						}}
						onOpen={() => {
							setIsDrawerOpen(true);
						}}
					>
						{data?.type === ProductType.Uf || data?.type === ProductType.Pf ? (
							<InfoIconDrawer
								title={cs.global.permanentResidence}
								description={
									<span
										dangerouslySetInnerHTML={{
											__html: cs.profile.person.dpsPermanentAddressInfo,
										}}
									></span>
								}
								bottomButtonAction={() => {
									setIsDrawerOpen(false);
								}}
							/>
						) : (
							<InfoIconDrawer
								title={cs.global.permanentResidence}
								description={
									<span
										dangerouslySetInnerHTML={{
											__html: cs.profile.person.lifePermanentAddressInfo,
										}}
									></span>
								}
								bottomButtonAction={() => {
									setIsDrawerOpen(false);
								}}
							/>
						)}
					</Modal>
				</Stack>
				<Stack sx={{ width: { md: "50%" }, ml: { md: 4 } }}>
					{isDesktop && (
						<StaticImage
							src="../../images/passport.png"
							alt="passport"
							height={180}
							objectFit="contain"
						/>
					)}
					<Stack
						sx={{
							flexDirection: { md: "column-reverse" },
							gap: { md: 5, xs: 0 },
						}}
					>
						<Stack spacing={4}>
							<InformationList
								isLoading={isLoading}
								title="Kontaktní adresa"
								action={
									process.env
										.GATSBY_FEATURE_IS_CONTACT_ADDRESS_CHANGE_ENABLED ===
									"true" ? (
										<ChangeAddressDrawer
											type={data?.type}
											contractNumber={data?.id}
											title={data?.title}
											disabled={
												formWasSend ||
												isContactAddressChangeInProgress ||
												isNotLifePolicyHolder
											}
											setFormWasSend={setFormWasSend}
										/>
									) : (
										<NotInMVP
											sx={{
												ml: { md: "40%", sm: "0" },
											}}
											text={cs.changeAddress.changeContactAddress}
											onClick={() =>
												handleClick(cs.profile.person.changeContactAddress)
											}
										/>
									)
								}
								information={[
									{
										title: "Adresa",
										description: <AddressTemplate address={contactAddress} />,
									},
								]}
							/>

							{(formWasSend || isContactAddressChangeInProgress) &&
								!isNotLifePolicyHolder && (
									<Attention severity="info" sx={{ mt: { xs: 5 } }}>
										{cs.global.contactAddressChangeInProgress}
									</Attention>
								)}
						</Stack>
						<Stack spacing={4}>
							{!data?.isChildrenContract && contactInfoItems.length !== 0 && (
								<InformationList
									isLoading={isLoading}
									title="Kontaktní údaje"
									action={
										process.env.GATSBY_FEATURE_IS_CONTACT_CHANGE_ENABLED ===
										"true" ? (
											contactChangeSuccess ? (
												<InternalButtonLink
													variant="text"
													size="small"
													href="/jsou-vase-udaje-spravne"
													disabled={hasContactChange || isNotLifePolicyHolder}
												>
													{cs.global.change}
												</InternalButtonLink>
											) : (
												<Skeleton variant="rounded" width={64} height={16} />
											)
										) : (
											<NotInMVP
												onClick={() => {
													handleClick("Kontaktní údaje");
												}}
											/>
										)
									}
									sx={{ mt: { md: 0, xxs: 5 } }}
									information={contactInfoItems}
								/>
							)}
							{contactChangeSuccess &&
								hasContactChange &&
								!isNotLifePolicyHolder && (
									<Attention severity="info" sx={{ mt: { xs: 5 } }}>
										{cs.global.contactChangeAlreadyInProgress}
									</Attention>
								)}
						</Stack>
					</Stack>
				</Stack>
			</Stack>
		</>
	);
}
export default ClientShared;
