import { Button, Stack } from "@mui/material";
import useIsDesktop from "desktop/useIsDesktop";
import { graphql, useStaticQuery } from "gatsby";
import withAITrackingShortcut from "metrics/withAITrackingShortcut";
import { useEffect } from "react";

import {
	PensionBeneficiary,
	PensionPendingChange,
	PensionPhaseStatus,
	ProductType,
	StarPensionClientTypeEnum,
} from "../../../models";
import { useContractId } from "../../context/ContractIDContext";
import { useContractTypeContext } from "../../context/ContractTypeContext";
import { usePrimaryAPIClient } from "../../context/PrimaryAPIClient";
import { dateTimeFormat } from "../../functions/dates";
import {
	useAllowedChangesForContract,
	usePensionDetail,
} from "../../queryHooks";
import { useContractById } from "../../queryHooks";
import cs from "../../translations/cs.json";
import { formatAmount } from "../../utils";
import Attention from "../AttentionBlock";
import ChangeBeneficiariesButton from "../ChangeBeneficiaries";
import BeneficiariesInformationList from "../ChangeBeneficiaries/BeneficiariesInformationList";
import { useSmartAction } from "../Contract/SmartAction/context";
import { useDrawer } from "../Drawer/context";
import { NotInMVPButton } from "../Drawer/NotInMVP";
import { BgImage } from "../Image/BgImage";
import HelpBox from "../InfoBox/HelpBox";
import InformationList from "../InformationList";
import { TriggerButton } from "../Link";
import { BodyTextM, HeadingL } from "../Typography";
import { mapFromV1Beneficiary } from "../ZivotniPojisteni/types";
import {
	contributionCoreSystemChangeDescription,
	employerContributionCoreSystemChangeDescription,
} from "./ContractDetail.helpers";
import ContributionDialog, {
	ContributionAction,
} from "./Contribution/ContributionDialog";
import { TaxOptimumDialog } from "./TaxOptimum/TaxOptimumDialog";

const frequencies = cs.frequencies;

// UF and PF contract detail
function ContractDetail({
	pendingChanges,
	isSuccessPendingChanges,
}: {
	pendingChanges: Array<PensionPendingChange> | undefined;
	isSuccessPendingChanges: boolean;
}): JSX.Element {
	const { action } = useSmartAction();
	const { showDrawer, setDrawerContent } = useDrawer();
	const id = useContractId();
	const { contractType } = useContractTypeContext();
	const { contractsPpApi, contractsDpsApi } = usePrimaryAPIClient();

	const {
		data: allowedChangesData,
		isSuccess: allowedChangesSuccess,
		refetch: refetchAllowedChanges,
	} = useAllowedChangesForContract({
		id: id,
		options: {
			enabled: !!id,
		},
	});

	const {
		data,
		refetch: refetchContractDetail,
		isSuccess,
		isLoading,
	} = usePensionDetail({ id });

	const { data: contractData } = useContractById({
		id: id,
		options: { refetchOnMount: false },
	});

	const isDesktop = useIsDesktop();

	const canChangeContributions =
		allowedChangesSuccess &&
		allowedChangesData?.canChangeEmployerOrClientContribution &&
		allowedChangesData?.canChangeIsEmployerContributing;

	const allowedChangesLoaded = allowedChangesSuccess && allowedChangesData;

	const zoomImage = () => {
		return useStaticQuery<Queries.ContractDetailZoomImageQuery>(
			graphql`
				query ContractDetailZoomImage {
					zoom: file(relativePath: { eq: "zoom.png" }) {
						childImageSharp {
							fixed(quality: 90) {
								...GatsbyImageSharpFixed_withWebp
							}
						}
					}
				}
			`
		);
	};

	useEffect(() => {
		if (isSuccess) {
			// show dialog after data is loaded
			if (action?.type === "Fond" || action?.type === "FondBankId") {
				handleChangeContributionButton(ContributionAction.Monthly);
			}
			if (action?.type === "EmployerContribution") {
				handleChangeContributionButton(ContributionAction.Employer);
			}
		}
	}, [isSuccess]);

	const {
		contract: {
			doesEmployerContribute,
			monthlyContributionClient,
			contractNumber,
			signatureDate,
			effectiveDate,
			isEarlyRetirement,
			earlyRetirementDate,
			pensionPlan,
			isTaxOptimum,
			monthlyContributionOthers,
			contributionFrequency,
		},
		beneficiaries,
		uiStatus,
	} = data || {
		clientInfo: { identificationStatus: undefined },
		contract: {
			doesEmployerContribute: undefined,
			monthlyContributionClient: undefined,
			contractNumber: undefined,
			signatureDate: undefined,
			effectiveDate: undefined,
			isEarlyRetirement: undefined,
			earlyRetirementDate: undefined,
			pensionPlan: undefined,
			isTaxOptimum: undefined,
			monthlyContributionOthers: undefined,
			contributionFrequency: undefined,
		},
		beneficiaries: [
			{
				firstName: undefined,
				lastName: undefined,
				dateOfBirth: undefined,
				relationship: undefined,
				ratio: undefined,
				isBank: false,
				address: {
					street: undefined,
					town: undefined,
					zip: undefined,
					country: undefined,
				},
			} as PensionBeneficiary,
		],
		uiStatus: {
			canChangeBeneficiaries: undefined,
			reasons: undefined,
		},
	};

	const ContributionDialogWithAI = withAITrackingShortcut(
		ContributionDialog,
		"Změna měsíčního příspěvku a příspěvku zaměstnavatele [dialog]"
	);

	const handleChangeTaxOptimumButton = () => {
		showDrawer();
		setDrawerContent(
			<TaxOptimumDialog contractId={id} contractType={contractType} />
		);
	};

	function handleChangeContributionButton(
		contributionAction: ContributionAction
	) {
		if (!isSuccess) return;
		const presetMonthlyContribution = (() => {
			if (
				action?.type === "Fond" &&
				action.context &&
				typeof (action.context as any).newContribution === "number"
			) {
				return (action.context as any).newContribution as number;
			}
			if (
				pendingChanges &&
				pendingChanges.length > 0 &&
				pendingChanges?.[0].detail.newClientContribution
			) {
				return pendingChanges?.[0].detail.newClientContribution;
			}
			return monthlyContributionClient;
		})();

		const currentEmployerContribution =
			pendingChanges && pendingChanges?.length > 0
				? pendingChanges?.[0].detail.willEmployerContribute
				: data.contract.doesEmployerContribute;

		showDrawer();
		setDrawerContent(
			<ContributionDialogWithAI
				currentValues={{
					monthlyContribution: monthlyContributionClient as number,
					doesEmployerContribute: currentEmployerContribution as boolean,
				}}
				presetValues={{
					monthlyContribution: presetMonthlyContribution as number,
					doesEmployerContribute: currentEmployerContribution as boolean,
				}}
				contractType={contractType}
				contractsPpApi={contractsPpApi}
				contractsDpsApi={contractsDpsApi}
				refetchOnSuccess={async () => {
					await refetchContractDetail();
				}}
				refetchOnError={async () => {
					await refetchAllowedChanges();
				}}
				id={id}
				includeAML={
					process.env.GATSBY_FEATURE_IS_AML_AND_BANK_ID_ENABLED === "true" &&
					data !== undefined &&
					!data.isAmlFulfilled &&
					(!("isChildrenContract" in data) || !data.isChildrenContract)
				}
				includeID={
					process.env.GATSBY_FEATURE_IS_AML_AND_BANK_ID_ENABLED === "true" &&
					data !== undefined &&
					!data.isClientIdentified &&
					data.clientType === StarPensionClientTypeEnum.Z &&
					(!("isChildrenContract" in data) || !data.isChildrenContract)
				}
				contributionAction={contributionAction}
			/>
		);
	}

	return (
		<>
			<BgImage
				image={isDesktop ? zoomImage().zoom?.childImageSharp?.fixed : undefined}
				style={{
					backgroundPosition: "right center",
					backgroundSize: "250px 261px",
				}}
			>
				<Stack
					sx={{
						width: { md: "68.8%" },
					}}
				>
					<HeadingL withoutScale>Detail smlouvy</HeadingL>
					{contractData?.pensionPhaseStatus === PensionPhaseStatus.Payout && (
						<Attention severity="info">
							{cs.global.cantChangeContractDataTerminatedContract}
						</Attention>
					)}
					<InformationList
						isLoading={isLoading}
						information={[
							{
								title: "Číslo smlouvy",
								description: contractNumber,
							},
							{
								title: "Datum podpisu",
								description: signatureDate
									? dateTimeFormat.format(new Date(signatureDate))
									: undefined,
							},
							{
								title: "Datum účinnosti",
								description: effectiveDate
									? dateTimeFormat.format(new Date(effectiveDate))
									: "-",
							},
							contractType === ProductType.Pf && {
								title: "Výsluhová penze sjednána",
								description: isEarlyRetirement ? "Ano" : "Ne",
							},
							contractType === ProductType.Pf &&
								isEarlyRetirement && {
									title: "Nárok na výsluhovou penzi",
									description: earlyRetirementDate
										? dateTimeFormat.format(new Date(earlyRetirementDate))
										: "Bez nároku",
								},
							contractType === ProductType.Pf && {
								title: "Penzijní plán",
								description: pensionPlan ? `Číslo ${pensionPlan}` : "-",
							},
							contractData?.pensionPhaseStatus === PensionPhaseStatus.Saving &&
								contractData.isChildrenContract !== true && {
									title: cs.taxOptimumDialog.wantTaxOptimum,
									description: isTaxOptimum ? "Ano" : "Ne",
									additionalElements: [
										<TriggerButton
											disabled={
												allowedChangesLoaded &&
												!allowedChangesData.canChangeTaxOptimum
											}
											variant="text"
											key={"tax-optimum-button"}
											size="small"
											onClick={handleChangeTaxOptimumButton}
										>
											{cs.global.change}
										</TriggerButton>,
									],
									secondRow: (
										<>
											{(() => {
												if (
													allowedChangesLoaded &&
													!allowedChangesData.canChangeTaxOptimum
												) {
													return (
														<BodyTextM color="info.main">
															{allowedChangesData.taxOptimumDescription}
														</BodyTextM>
													);
												}
											})()}
										</>
									),
								},
							contractData?.pensionPhaseStatus ===
								PensionPhaseStatus.Saving && {
								title: "Můj měsíční příspěvek",
								description: monthlyContributionClient
									? `${formatAmount(monthlyContributionClient)} Kč`
									: undefined,
								additionalElements: [
									<Button
										variant="text"
										onClick={() =>
											handleChangeContributionButton(ContributionAction.Monthly)
										}
										key="employer-contribution"
										data-test="pension-monthly-contribution-button"
										disabled={!canChangeContributions}
									>
										<BodyTextM>Změnit částku</BodyTextM>
									</Button>,
								],
								valueSelector: "pension-monthly-contribution-value",
								secondRow: (
									<>
										{(() => {
											// primary present failed pending changes, rather than core system changes
											if (
												allowedChangesLoaded &&
												!allowedChangesData.canChangeEmployerOrClientContribution
											) {
												return (
													<BodyTextM color="info.main">
														{allowedChangesData.contributionDescription}
													</BodyTextM>
												);
											} else {
												return (
													isSuccessPendingChanges &&
													contributionCoreSystemChangeDescription(
														pendingChanges
													)?.map((s) => (
														<BodyTextM key={s} color="info.main">
															{s}
														</BodyTextM>
													))
												);
											}
										})()}
									</>
								),
							},
							contractData?.pensionPhaseStatus === PensionPhaseStatus.Saving &&
								contractData.isChildrenContract !== true && {
									title: "Příspěvek zaměstnavatele",
									description: doesEmployerContribute
										? "Zaměstnavatel mi přispívá"
										: "Zaměstnavatel mi nepřispívá",
									additionalElements: [
										<Button
											variant="text"
											onClick={() =>
												handleChangeContributionButton(
													ContributionAction.Employer
												)
											}
											key="employer-contribution"
											data-test="pension-employer-contribution-button"
											disabled={!canChangeContributions}
										>
											<BodyTextM>Změnit</BodyTextM>
										</Button>,
									],
									valueSelector: "pension-employer-contribution-value",
									secondRow: (
										<>
											{(() => {
												// primary present failed pending changes, rather than core system changes
												if (
													allowedChangesLoaded &&
													!allowedChangesData.canChangeIsEmployerContributing
												) {
													return (
														<BodyTextM color="info.main">
															{
																allowedChangesData.isEmployerContributingDescription
															}
														</BodyTextM>
													);
												} else {
													return (
														isSuccessPendingChanges && (
															<BodyTextM color="info.main">
																{employerContributionCoreSystemChangeDescription(
																	pendingChanges,
																	!!doesEmployerContribute
																)}
															</BodyTextM>
														)
													);
												}
											})()}
										</>
									),
								},
							contractData?.pensionPhaseStatus === PensionPhaseStatus.Saving &&
								contractType === ProductType.Pf && {
									title: "Sjednaná výše měsíčního příspěvku třetí osoby",
									description: `${monthlyContributionOthers ?? "-"} Kč`,
								},
							contractData?.pensionPhaseStatus ===
								PensionPhaseStatus.Saving && {
								title: "Frekvence placení",
								description: contributionFrequency
									? frequencies[contributionFrequency]
									: undefined,
							},
						]}
					/>
					{isSuccessPendingChanges &&
						pendingChanges &&
						pendingChanges.length > 0 && (
							<Attention severity="info" sx={{ mt: 4 }}>
								{cs.contributionAttention}
							</Attention>
						)}
				</Stack>
			</BgImage>
			{contractData?.pensionPhaseStatus === PensionPhaseStatus.Saving && (
				<>
					<Stack>
						<Stack
							sx={{
								flexDirection: { md: "row" },
								alignItems: { md: "baseline" },
							}}
						>
							<HeadingL
								withoutScale
								data-test="dps-beneficiaries-home-page-header"
							>
								{contractType
									? cs.product.beneficiaries[contractType]
									: cs.product.beneficiaries.default}
							</HeadingL>
						</Stack>
						<Stack spacing={{ xxs: 4, md: 0 }}>
							<BeneficiariesInformationList
								beneficiaries={beneficiaries.map(mapFromV1Beneficiary)}
								isLoading={isLoading}
							/>
							{(contractType === ProductType.Uf &&
								process.env
									.GATSBY_FEATURE_IS_DPS_BENEFICIENTS_CHANGE_ENABLED ===
									"true") ||
							(contractType === ProductType.Pf &&
								process.env.GATSBY_FEATURE_IS_PP_BENEFICIENTS_CHANGE_ENABLED ===
									"true") ? (
								<ChangeBeneficiariesButton
									beneficiaries={beneficiaries}
									refetchOnSuccess={async () => {
										await refetchContractDetail();
									}}
									refetchOnError={async () => {
										await refetchAllowedChanges();
									}}
									isLoading={isLoading || !allowedChangesSuccess}
									uiStatus={
										allowedChangesData &&
										allowedChangesData.canChangeBeneficiaries === false
											? { canChangeBeneficiaries: false, reasons: [] }
											: uiStatus
									}
									customDisabledReason={
										allowedChangesSuccess &&
										!allowedChangesData.canChangeBeneficiaries
											? (allowedChangesData.beneficiariesDescription as string)
											: undefined
									}
								/>
							) : (
								<NotInMVPButton
									text={cs.product.beneficiaries.changeBeneficiar.default}
									onClick={undefined}
								/>
							)}
						</Stack>
					</Stack>
				</>
			)}

			<HelpBox />
		</>
	);
}
export default ContractDetail;
