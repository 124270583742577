import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import { secondsToMilliseconds } from "date-fns";
import useIsDesktop from "desktop/useIsDesktop";
import { Formik } from "formik";
import { AnimatePresence } from "framer-motion";
import { useEffect, useRef, useState } from "react";

import { ConsentType } from "../../../../models";
import ProcessingBanner from "../../../components/AttentionBlock/ProcessingBanner";
import Form from "../../../components/Form/Form";
import RadioInput from "../../../components/Form/Input/Radio";
import RadioGroup from "../../../components/Form/Input/Radio/RadioGroup";
import { agreement } from "../../../components/Form/rules";
import { SEO } from "../../../components/SEO";
import { BodyTextM, HeadingM } from "../../../components/Typography";
import withProtection from "../../../highOrderComponents/withProtection";
import { useConsents, usePutConsents } from "../../../queryHooks/consents";
import cs from "../../../translations/cs.json";

export const Title = () => cs.profile.marketing.header;

export const MarketingTextWithPartnerLink = () => {
	return (
		<>
			<BodyTextM>
				{cs.profile.marketing.content}
				{" ("}
				<a
					href="https://www.nn.cz/files/archiv/seznam_partneru_NN-Finance.pdf"
					target="_blank"
					rel="noreferrer"
				>
					{cs.profile.marketing.partnersListLink}
				</a>
				{")."}
			</BodyTextM>
		</>
	);
};

export const MarketingGdprTextWithLink = () => {
	return (
		<>
			<BodyTextM>
				{cs.profile.marketing.content2}{" "}
				<a
					target="_blank"
					href="https://www.nn.cz/files/archiv/cz-nn/ochrana_ou/NN_Finance_pouceni_OU.pdf"
					rel="noreferrer"
				>
					{cs.profile.marketing.gdprLink}
				</a>
			</BodyTextM>
		</>
	);
};

const Info = (): JSX.Element => {
	const [showAttention, setShowAttention] = useState(false);
	// eslint-disable-next-line no-undef
	const attentionTimeOut = useRef<ReturnType<typeof setTimeout>>();
	const { data: marketingEnabled, isSuccess } = useConsents<
		boolean | undefined
	>({
		options: {
			select: (data) => {
				return data.find((consent) => consent.type === ConsentType.Marketing)
					?.consent;
			},
		},
	});
	const { mutateAsync, status } = usePutConsents();
	const isDesktop = useIsDesktop();

	useEffect(() => {
		if (status === "success" || status === "error") {
			if (attentionTimeOut.current) {
				clearTimeout(attentionTimeOut.current);
			}
			setShowAttention(true);

			attentionTimeOut.current = setTimeout(() => {
				setShowAttention(false);
			}, secondsToMilliseconds(3));
		}

		return () => {
			attentionTimeOut.current && clearTimeout(attentionTimeOut.current);
		};
	}, [status]);

	return (
		<>
			{isDesktop && (
				<AnimatePresence>
					{showAttention && <ProcessingBanner isError={status === "error"} />}
				</AnimatePresence>
			)}
			<HeadingM>{cs.profile.marketing.header}</HeadingM>
			<MarketingTextWithPartnerLink />
			<Paper sx={{ p: 4 }}>
				{isSuccess ? (
					<Formik
						initialValues={{ agreement: marketingEnabled?.toString() }}
						validationSchema={agreement}
						onSubmit={async ({ agreement }) => {
							await mutateAsync({
								setConsentRequest: {
									consentType: ConsentType.Marketing,
									consent: agreement === "true",
								},
							});
						}}
					>
						<Form>
							<RadioGroup
								defaultValue={marketingEnabled?.toString()}
								name="agreement"
							>
								<RadioInput
									name="agreement"
									value="true"
									label="Souhlasím"
									id="agree"
								/>
								<RadioInput
									name="agreement"
									value="false"
									label="Nesouhlasím"
									id="disagree"
								/>
							</RadioGroup>
						</Form>
					</Formik>
				) : (
					<Skeleton variant="rounded" height={162} />
				)}
			</Paper>
			{!isDesktop && (
				<AnimatePresence>
					{showAttention && <ProcessingBanner isError={status === "error"} />}
				</AnimatePresence>
			)}
			<MarketingGdprTextWithLink />
		</>
	);
};

export default withProtection(Info);

export const Head = (): JSX.Element => (
	<SEO title="Souhlas">
		<meta name="robots" content="noindex" />
	</SEO>
);
