import { useLocation } from "@reach/router";
import { useMemo } from "react";

import { EnvironmentHelper } from "../functions/env";

type Param = { name: string; required?: boolean };

export const useParams = (...params: Param[]) => {
	const memoizedParamsNonBrowser = useMemo(() => {
		return params.map((param) => param.name);
	}, [JSON.stringify(params)]);

	if (!EnvironmentHelper.isBrowser()) return memoizedParamsNonBrowser;

	const { href } = useLocation();

	const memoizedParamValues = useMemo(() => {
		const searchParams = new URL(href).searchParams;

		return params.map((param) => {
			if (param.required === true && !searchParams.has(param.name))
				throw new Error(`Missing required URL param ${param.name}`);
			return searchParams.get(param.name);
		});
	}, [href, JSON.stringify(params)]);

	return memoizedParamValues;
};
