/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp.General
 * GeneralAPIs
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ActiveDirectoryUserIdResponse,
  BankIdCreateLoginPayloadResponse,
  BankIdLoginPayloadRequest,
  BankIdRegisterPayloadRequest,
  BankIdValidateLoginRequest,
} from '../models/index';
import {
    ActiveDirectoryUserIdResponseFromJSON,
    ActiveDirectoryUserIdResponseToJSON,
    BankIdCreateLoginPayloadResponseFromJSON,
    BankIdCreateLoginPayloadResponseToJSON,
    BankIdLoginPayloadRequestFromJSON,
    BankIdLoginPayloadRequestToJSON,
    BankIdRegisterPayloadRequestFromJSON,
    BankIdRegisterPayloadRequestToJSON,
    BankIdValidateLoginRequestFromJSON,
    BankIdValidateLoginRequestToJSON,
} from '../models/index';

export interface AuthorizeLoginPostRequest {
    bankIdValidateLoginRequest?: BankIdValidateLoginRequest;
}

export interface ProcessBankIdLoginCallbackPostRequest {
    bankIdLoginPayloadRequest?: BankIdLoginPayloadRequest;
}

export interface ProcessBankIdRegisterPostRequest {
    bankIdRegisterPayloadRequest?: BankIdRegisterPayloadRequest;
}

/**
 * 
 */
export class BankIdApi extends runtime.BaseAPI {

    /**
     * Validate ciper, tag, nonce
     */
    async authorizeLoginPostRaw(requestParameters: AuthorizeLoginPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ActiveDirectoryUserIdResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/bank-id/authorize-login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BankIdValidateLoginRequestToJSON(requestParameters['bankIdValidateLoginRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ActiveDirectoryUserIdResponseFromJSON(jsonValue));
    }

    /**
     * Validate ciper, tag, nonce
     */
    async authorizeLoginPost(requestParameters: AuthorizeLoginPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ActiveDirectoryUserIdResponse> {
        const response = await this.authorizeLoginPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Try login portal client by bank-id
     */
    async processBankIdLoginCallbackPostRaw(requestParameters: ProcessBankIdLoginCallbackPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BankIdCreateLoginPayloadResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/bank-id/login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BankIdLoginPayloadRequestToJSON(requestParameters['bankIdLoginPayloadRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BankIdCreateLoginPayloadResponseFromJSON(jsonValue));
    }

    /**
     * Try login portal client by bank-id
     */
    async processBankIdLoginCallbackPost(requestParameters: ProcessBankIdLoginCallbackPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BankIdCreateLoginPayloadResponse> {
        const response = await this.processBankIdLoginCallbackPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Try register portal client by bank-id
     */
    async processBankIdRegisterPostRaw(requestParameters: ProcessBankIdRegisterPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BankIdCreateLoginPayloadResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/bank-id/register`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BankIdRegisterPayloadRequestToJSON(requestParameters['bankIdRegisterPayloadRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BankIdCreateLoginPayloadResponseFromJSON(jsonValue));
    }

    /**
     * Try register portal client by bank-id
     */
    async processBankIdRegisterPost(requestParameters: ProcessBankIdRegisterPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BankIdCreateLoginPayloadResponse> {
        const response = await this.processBankIdRegisterPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
