/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp.General
 * GeneralAPIs
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EncryptUserDetailInfoRequest
 */
export interface EncryptUserDetailInfoRequest {
    /**
     * 
     * @type {string}
     * @memberof EncryptUserDetailInfoRequest
     */
    birthdate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EncryptUserDetailInfoRequest
     */
    birthNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EncryptUserDetailInfoRequest
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EncryptUserDetailInfoRequest
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EncryptUserDetailInfoRequest
     */
    userId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EncryptUserDetailInfoRequest
     */
    targetUserId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EncryptUserDetailInfoRequest
     */
    targetObjectId?: string | null;
}

/**
 * Check if a given object implements the EncryptUserDetailInfoRequest interface.
 */
export function instanceOfEncryptUserDetailInfoRequest(value: object): value is EncryptUserDetailInfoRequest {
    return true;
}

export function EncryptUserDetailInfoRequestFromJSON(json: any): EncryptUserDetailInfoRequest {
    return EncryptUserDetailInfoRequestFromJSONTyped(json, false);
}

export function EncryptUserDetailInfoRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): EncryptUserDetailInfoRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'birthdate': json['birthdate'] == null ? undefined : json['birthdate'],
        'birthNumber': json['birthNumber'] == null ? undefined : json['birthNumber'],
        'firstName': json['firstName'] == null ? undefined : json['firstName'],
        'lastName': json['lastName'] == null ? undefined : json['lastName'],
        'userId': json['userId'] == null ? undefined : json['userId'],
        'targetUserId': json['targetUserId'] == null ? undefined : json['targetUserId'],
        'targetObjectId': json['targetObjectId'] == null ? undefined : json['targetObjectId'],
    };
}

export function EncryptUserDetailInfoRequestToJSON(value?: EncryptUserDetailInfoRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'birthdate': value['birthdate'],
        'birthNumber': value['birthNumber'],
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'userId': value['userId'],
        'targetUserId': value['targetUserId'],
        'targetObjectId': value['targetObjectId'],
    };
}

