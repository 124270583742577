import { Box, Stack } from "@mui/material";
import Button from "@mui/material/Button";
import { useState } from "react";

import { PensionPhaseStatus, ProductType } from "../../../models";
import { useContractId } from "../../context/ContractIDContext";
import { useContractById, usePensionDetail } from "../../queryHooks";
import cs from "../../translations/cs.json";
import { formatAmount } from "../../utils";
import CorrectPaymentSymbols from "../AttentionBlock/CorrectPaymentSymbols";
import RecurringPayment from "../AttentionBlock/RecurringPayment";
import InfoIconDrawer from "../Drawer/InfoIconDrawer";
import Modal from "../Drawer/Modal";
import HelpBox from "../InfoBox/HelpBox";
import InformationList from "../InformationList";
import QrCodePayment from "../QRCode/QrCodePayment";
import { BodyTextM, HeadingL } from "../Typography";
import TransactionsSubComponent from "./Transactions/TransasctionSubComponent";

const frequencies = cs.frequencies;

/*shared components between DPS and PP contract pages */

function InformationList1({ pensionPhaseStatus }) {
	const id = useContractId();

	const [isDrawerOpen, setIsDrawerOpen] = useState(false);

	const result = usePensionDetail({ id });

	const { clientPaymentInfo: cpi } = result.data || {
		clientPaymentInfo: {
			monthlyContribution: undefined,
			accountNumber: undefined,
			constantSymbol: undefined,
			variableSymbol: undefined,
			frequency: undefined,
			iban: undefined,
		},
	};

	return (
		<>
			{pensionPhaseStatus === PensionPhaseStatus.Payout ? (
				<InformationList
					isLoading={result.isLoading}
					title="Účastník"
					information={[
						{
							title: "Číslo účtu",
							description: cpi.accountNumber,
						},
						{
							title: "Variabilní symbol",
							description: cpi.variableSymbol,
						},
					]}
				/>
			) : (
				<>
					<InformationList
						isLoading={result.isLoading}
						title="Účastník"
						action={
							<Button
								variant="action"
								onClick={() => {
									setIsDrawerOpen(true);
								}}
							>
								<BodyTextM>Jak nastavit trvalý příkaz</BodyTextM>
							</Button>
						}
						information={[
							{
								title: "Číslo účtu",
								description: cpi.accountNumber,
							},
							{
								title: "Měsíční příspěvek",
								description: cpi.monthlyContribution
									? `${formatAmount(cpi.monthlyContribution)} Kč`
									: "-",
							},
							{
								title: "Konstantní symbol",
								description: cpi.constantSymbol,
							},
							{
								title: "Variabilní symbol",
								description: cpi.variableSymbol,
							},
							{
								title: "Frekvence placení",
								description: frequencies[cpi.frequency],
							},
							{
								title: "IBAN",
								description: cpi.iban,
							},
							{
								description: (
									<QrCodePayment
										accountNumber={cpi.accountNumber}
										amount={cpi.monthlyContribution}
										constantSymbol={cpi.constantSymbol}
										variableSymbol={cpi.variableSymbol}
										iban={cpi.iban}
									/>
								),
							},
						]}
					/>
					<Modal
						open={isDrawerOpen}
						onClose={() => {
							setIsDrawerOpen(false);
						}}
						onOpen={() => {
							setIsDrawerOpen(true);
						}}
					>
						<InfoIconDrawer
							title={cs.global.howToSetRecurringPayment}
							description={
								<>
									V internetovém bankovnictví své banky zvolíte „trvalý příkaz k
									úhradě“. V menu může být pod položkou „poslat peníze“ apod. V
									následném formuláři vyplníte číslo účtu, kam budete své
									penzijní spoření posílat <strong>{cpi.accountNumber}</strong>{" "}
									a frekvenci <strong>{cs.frequencies2[cpi.frequency]}</strong>.
									Dále konstantní <strong>{cpi.constantSymbol}</strong> a
									variabilní <strong>{cpi.variableSymbol}</strong> symbol,
									případně IBAN <strong>{cpi.iban}</strong>. Můžete zadat i
									konečné datum trvalého příkazu.
								</>
							}
							bottomButtonAction={() => {
								setIsDrawerOpen(false);
							}}
						/>
					</Modal>
				</>
			)}
		</>
	);
}

function InformationList2({ pensionPhaseStatus }) {
	const id = useContractId();

	const result = usePensionDetail({ id });

	const { employerPaymentInfo: epi } = result.data || {
		employerPaymentInfo: {
			accountNumber: undefined,
			constantSymbol: undefined,
			variableSymbol: undefined,
			frequency: undefined,
			iban: undefined,
		},
	};

	return (
		<>
			{pensionPhaseStatus === PensionPhaseStatus.Payout ? (
				<InformationList
					isLoading={result.isLoading}
					title="Zaměstnavatel"
					information={[
						{
							title: "Číslo účtu",
							description: epi.accountNumber,
						},
						{
							title: "Variabilní symbol",
							description: epi.variableSymbol,
						},
					]}
				/>
			) : (
				<InformationList
					isLoading={result.isLoading}
					title="Zaměstnavatel"
					information={[
						{
							title: "Číslo účtu",
							description: epi.accountNumber,
						},
						{
							title: "Konstantní symbol",
							description: epi.constantSymbol,
						},
						{
							title: "Variabilní symbol",
							description: epi.variableSymbol,
						},
						{
							title: "Frekvence placení",
							description: frequencies[epi.frequency],
						},
						{
							title: "IBAN",
							description: epi.iban,
						},
					]}
				/>
			)}
		</>
	);
}

function Transactions(): JSX.Element {
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);
	const id = useContractId();
	const { data } = useContractById({
		id: id,
		options: { refetchOnMount: false },
	});
	return (
		<>
			<Stack sx={{ flexDirection: { md: "row" } }} gap={{ md: 0, xxs: 5 }}>
				<Box sx={{ width: { md: "50%" }, mr: { md: 4 } }}>
					<TransactionsSubComponent
						isDrawerOpen={isDrawerOpen}
						setIsDrawerOpen={setIsDrawerOpen}
					/>
				</Box>
				<Box sx={{ width: { md: "50%" }, ml: { md: 4 } }}>
					{data?.pensionPhaseStatus === PensionPhaseStatus.Saving && (
						<HeadingL withoutScale>Jak zaplatit příspěvek</HeadingL>
					)}
					<Stack spacing={5}>
						<RecurringPayment pensionPhaseStatus={data?.pensionPhaseStatus} />
						<CorrectPaymentSymbols
							pensionPhaseStatus={data?.pensionPhaseStatus}
						/>
						<InformationList1 pensionPhaseStatus={data?.pensionPhaseStatus} />
						{!(
							data?.isChildrenContract === true && data.type === ProductType.Uf
						) && (
							<InformationList2 pensionPhaseStatus={data?.pensionPhaseStatus} />
						)}
					</Stack>
				</Box>
			</Stack>
			<HelpBox />
		</>
	);
}

export default Transactions;
