import { Stack } from "@mui/material";
import { Formik } from "formik";
import { isNil } from "lodash";
import React, { useEffect, useState } from "react";
import { bool, object } from "yup";

import {
	GetLifeDetailV2Response,
	LifeTransactionEnum,
	ProductType,
} from "../../../../../../models";
import { useContractId } from "../../../../../context/ContractIDContext";
import { useContractTypeContext } from "../../../../../context/ContractTypeContext";
import { FetchApiError } from "../../../../../context/PrimaryAPIClient";
import {
	isUnsupportedFufiContractTypeError,
	LifeTransactionsQueryParams,
	transactionsPerRequest,
	useLifeDetail,
	useLifeTransactions,
} from "../../../../../queryHooks";
import cs from "../../../../../translations/cs.json";
import {
	assertIsLifeContractType,
	LifeContractType,
} from "../../../../../types/contracts";
import { CustomApiError } from "../../../../../types/errors";
import Attention from "../../../../AttentionBlock";
import Modal from "../../../../Drawer/Modal";
import { dateRangeFrom2010 } from "../../../../Form/rules";
import { Trigger } from "../../../../Link";
import { HeadingL } from "../../../../Typography";
import FilterTransactionsDrawer from "./FilterTransactionsDrawer";
import { LoadingStatus, Table } from "./Table";

const transactionsPerPage = 6;

const initialTransactionCounts = {
	transactionsToLoad: transactionsPerRequest,
	transactionsToShow: transactionsPerPage,
};

const initialParams: LifeTransactionsQueryParams = {
	typeFilter: [
		LifeTransactionEnum.LifeInsurer,
		LifeTransactionEnum.LifeEmployer,
	],
};

const initialValues = {
	dateFrom: "",
	dateTo: "",
	LIFE_INSURER: true,
	LIFE_EMPLOYER: true,
	LIFE_PAY_IN: false,
	LIFE_PAY_OUT: false,
};

const fromToValidation = object().shape({
	...dateRangeFrom2010,
	LIFE_INSURER: bool(),
	LIFE_EMPLOYER: bool(),
	LIFE_PAY_IN: bool(),
	LIFE_PAY_OUT: bool(),
	isAnySelected: bool().test("isAnySelected", "error", function () {
		return (
			this.parent.LIFE_INSURER ||
			this.parent.LIFE_EMPLOYER ||
			this.parent.LIFE_PAY_IN ||
			this.parent.LIFE_PAY_OUT
		);
	}),
});

interface LifeTransactionsWithDataProps {
	contractType: LifeContractType;
	isDrawerOpen: boolean;
	setIsDrawerOpen: (isDrawerOpen: boolean) => void;
	setIsProductSupported: (unsupported: boolean) => void;
}

export const TransactionsNotSupported = () => {
	return (
		<Attention severity="warning">
			{cs.life.transactions.transactionsNotSupported}
		</Attention>
	);
};

const LifeTransactionsComponent = ({
	contractType,
	isDrawerOpen,
	setIsDrawerOpen,
	setIsProductSupported,
}: LifeTransactionsWithDataProps) => {
	const id = useContractId();

	const [transactionCounts, setTransactionCounts] = useState(
		initialTransactionCounts
	);

	const [params, setParams] = useState<LifeTransactionsQueryParams>();

	const { data, isSuccess, isPreviousData, isError, error } =
		useLifeTransactions({
			contractId: id,
			contractType,
			params: {
				...(params ? params : initialParams),
				count: transactionCounts.transactionsToLoad,
			},
			options: {
				keepPreviousData: params === undefined,
				useErrorBoundary: (error) => {
					return !isUnsupportedFufiContractTypeError(error, contractType);
				},
				retry: (failureCount, error) => {
					if (failureCount >= 3) return false;
					return !isUnsupportedFufiContractTypeError(error, contractType);
				},
			},
		});

	const { data: contract, isSuccess: contractSuccess } =
		useLifeDetail<GetLifeDetailV2Response>({
			id,
			contractType: ProductType.Clf,
			options: {
				enabled: contractType === ProductType.Clf,
			},
		});

	useEffect(() => {
		if (!isNil(error)) {
			setIsProductSupported(
				!(
					error instanceof FetchApiError &&
					error.errorResponse.code === CustomApiError.MethodNotAllowed
				)
			);
		}
	}, [error, setIsProductSupported]);

	const loadMoreTransactions = () => {
		if (!isSuccess) return;
		setTransactionCounts((prev) => {
			const newVisibleTransactionsCount =
				prev.transactionsToShow + transactionsPerPage;
			const newTransactionsToLoad =
				newVisibleTransactionsCount > prev.transactionsToLoad
					? prev.transactionsToLoad + transactionsPerRequest
					: prev.transactionsToLoad;

			return {
				transactionsToShow: newVisibleTransactionsCount,
				transactionsToLoad: newTransactionsToLoad,
			};
		});
	};

	const visibleTransactions = (() => {
		if (!isSuccess) return [];
		return data
			.slice(0, transactionCounts.transactionsToShow)
			.map((transaction) => ({
				...transaction,
				title: (() => {
					if (
						contractType === ProductType.Cl ||
						transaction.typeFull != "Regular" ||
						!contract?.paymentFrequency
					) {
						return transaction.title;
					}
					return `${cs.frequencies[contract?.paymentFrequency]} ${
						cs.global.dueInsurance
					}`;
				})(),
			}));
	})();

	const loadingStatus = (() => {
		if (!isSuccess) return LoadingStatus.Initial;
		if (!contractSuccess && contractType === ProductType.Clf)
			return LoadingStatus.Initial;
		if (isPreviousData) return LoadingStatus.Additional;
		return LoadingStatus.Ready;
	})();

	if (isError) return <TransactionsNotSupported />;

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={fromToValidation}
			onSubmit={(values, formik) => {
				setIsDrawerOpen(false);
				const newParams: LifeTransactionsQueryParams = {
					typeFilter: [
						values.LIFE_EMPLOYER && LifeTransactionEnum.LifeEmployer,
						values.LIFE_INSURER && LifeTransactionEnum.LifeInsurer,
						values.LIFE_PAY_IN && LifeTransactionEnum.LifePayIn,
						values.LIFE_PAY_OUT && LifeTransactionEnum.LifePayOut,
					].filter((value) => !!value),
					to: values.dateTo ? new Date(values.dateTo) : undefined,
					from: values.dateFrom ? new Date(values.dateFrom) : undefined,
				};
				setParams(newParams);
				setTransactionCounts(initialTransactionCounts);
				formik.setSubmitting(false);
			}}
			onReset={() => setParams(undefined)}
		>
			{() => {
				return (
					<React.Fragment>
						<Modal
							open={isDrawerOpen}
							onClose={() => {
								setIsDrawerOpen(false);
							}}
						>
							<FilterTransactionsDrawer
								handleHideDrawer={() => setIsDrawerOpen(false)}
							/>
						</Modal>
						<Table
							data={visibleTransactions}
							loadingStatus={loadingStatus}
							fetchMore={loadMoreTransactions}
							displayLoadMoreButton={
								!isSuccess ||
								isPreviousData ||
								data.length === transactionCounts.transactionsToLoad ||
								data.length > transactionCounts.transactionsToShow
							}
						/>
					</React.Fragment>
				);
			}}
		</Formik>
	);
};

const titleByContractType: Record<LifeContractType, string> = {
	[ProductType.Cl]: cs.life.transactions.transactionsPaymentLife,
	[ProductType.Clf]: cs.life.transactions.transactionsPaymentFufi,
};

interface ITransactionCardHeader {
	contractType: LifeContractType;
	switchOpen: () => void;
	disabled?: boolean;
}

export const TransactionCardHeader = ({
	switchOpen,
	disabled = false,
	contractType,
}: ITransactionCardHeader) => {
	return (
		<Stack direction="row" justifyContent="space-between" gap={4}>
			<HeadingL withoutScale sx={{ m: 0 }}>
				{titleByContractType[contractType]}
			</HeadingL>
			<Trigger disabled={disabled} onClick={switchOpen}>
				Filtrovat
			</Trigger>
		</Stack>
	);
};

export const TransactionsCardWithData = () => {
	const [isSupported, setIsProductSupported] = useState(true);
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);
	const { contractType } = useContractTypeContext();

	assertIsLifeContractType(contractType);

	return (
		<Stack sx={{ gap: 4 }}>
			<TransactionCardHeader
				contractType={contractType}
				disabled={!isSupported}
				switchOpen={() => {
					setIsDrawerOpen((open) => !open);
				}}
			/>
			<LifeTransactionsComponent
				contractType={contractType}
				isDrawerOpen={isDrawerOpen}
				setIsDrawerOpen={setIsDrawerOpen}
				setIsProductSupported={setIsProductSupported}
			/>
		</Stack>
	);
};
