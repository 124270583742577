import { useMsal } from "@azure/msal-react";

export const useUserIdentifier = (): null | string => {
	const { instance } = useMsal();
	const account = instance.getActiveAccount();
	if (account === null || account.idTokenClaims === undefined) return null;

	if ("extension_UserDetailInfoEncrypted" in account.idTokenClaims) {
		return account.idTokenClaims.extension_UserDetailInfoEncrypted as string;
	}

	if ("extension_PID" in account.idTokenClaims) {
		return account.idTokenClaims.extension_PID as string;
	}
	return null;
};
