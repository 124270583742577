import { Skeleton } from "@mui/material";

import {
	GetLifeDetailResponse,
	GetLifeDetailV2Response,
	InsuredPerson,
	InsuredPersonRole,
	NdpInsuredPerson,
	ProductType,
} from "../../../../models";
import { useContractId } from "../../../context/ContractIDContext";
import { useLifeDetail } from "../../../queryHooks";
import cs from "../../../translations/cs.json";

type GetSubtitleProps =
	| {
			titlePrefix?: string;
			firstName?: string;
			lastName?: string;
			birthDate?: string | Date;
			titleSuffix?: string;
	  }
	| undefined
	| null;

export const getSubtitleFromPerson = (props: GetSubtitleProps) => {
	if (props === undefined) {
		return undefined;
	}
	if (props === null) {
		return "První pojištěný nebyl nalezen";
	}
	const strings = [
		props.titlePrefix,
		props.firstName,
		props.lastName,
		props.titleSuffix,
		props.birthDate ? new Date(props.birthDate).getFullYear() : null,
	].filter((string) => !!string && string !== "");

	return strings.join(" ");
};

export const findFirstPerson = (persons: InsuredPerson[] | undefined) => {
	if (persons === undefined) return;
	const firstPerson = persons.find(
		(person) =>
			InsuredPersonRole.First === person.role ||
			InsuredPersonRole.PolicyHolderAndFirst === person.role
	);
	return firstPerson;
};

export const findFufiFirstPerson = (
	persons: NdpInsuredPerson[] | undefined
) => {
	if (persons === undefined) return;
	const firstPerson = persons.find((person) => person.order === 1);
	return firstPerson?.person;
};

interface ISubtitle {
	subtitle: string | undefined;
}

export const Subtitle = ({ subtitle }: ISubtitle) => {
	return subtitle ? subtitle : <Skeleton />;
};

export const FufiSubtitle = () => {
	const contractId = useContractId();
	const { data } = useLifeDetail<GetLifeDetailV2Response>({
		id: contractId,
		contractType: ProductType.Clf,
	});
	const firstPerson = findFufiFirstPerson(data?.insuredPersons);

	const subtitle = firstPerson
		? [
				`${cs.life.global.owner}:`,
				firstPerson.titlePrefix,
				firstPerson.firstName,
				firstPerson.lastName,
				firstPerson.titleSuffix,
		  ]
				.filter((str) => !!str)
				.join(" ")
		: undefined;

	return <Subtitle subtitle={subtitle} />;
};

export const LifeSubtitle = () => {
	const contractId = useContractId();
	const { data } = useLifeDetail<GetLifeDetailResponse>({ id: contractId });
	const firstPerson = findFirstPerson(data?.insuredPersons);
	const subtitle = getSubtitleFromPerson(
		(() => {
			if (!firstPerson) return firstPerson;
			return {
				firstName: firstPerson.firstName ?? undefined,
				lastName: firstPerson.lastName ?? undefined,
				birthDate: firstPerson.dateOfBirth ?? undefined,
			};
		})()
	);
	return <Subtitle subtitle={subtitle} />;
};
