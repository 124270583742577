import { InteractionStatus } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { LoadingButton } from "@mui/lab";

import useLogin from "./useLogin";

export interface ILoginButton {
	journey: LoginButtonType;
}

export type LoginButtonType =
	| "bank-id-direct"
	| "bank-id"
	| "local-login"
	| "local-registration";

const variantByJourney = {
	"bank-id-direct": "bank-id",
	"bank-id": "bank-id",
	"local-login": "contained",
	"local-registration": "contained",
};

const labelByJourney =
	process.env.GATSBY_FEATURE_IS_SIMPLE_GUIDEPOST_ENABLED === "true"
		? {
				"bank-id-direct": "",
				"bank-id": "",
				"local-login": "Přihlásit se",
				"local-registration": "Zaregistrovat se",
		  }
		: {
				"bank-id-direct": "Přihlásit se přes BankID",
				"bank-id": "Přihlásit se přes BankID",
				"local-login": "Přihlásit se",
				"local-registration": "Zaregistrovat se",
		  };

const LoginButton = ({ journey, ...rest }: ILoginButton): JSX.Element => {
	const { click, isDisabled } = useLogin(journey);
	const { inProgress } = useMsal();

	const loading = inProgress !== InteractionStatus.None;
	return (
		<LoadingButton
			{...rest}
			onClick={click}
			disabled={isDisabled}
			variant={
				loading && ["bank-id", "bank-id-direct"].includes(journey)
					? "contained"
					: variantByJourney[journey]
			}
			size={["bank-id", "bank-id-direct"].includes(journey) ? "small" : "large"}
			underline="none"
			disableElevation={true}
			loading={loading}
			style={{
				minHeight: "51px",
			}}
		>
			{labelByJourney[journey]}
		</LoadingButton>
	);
};

export default LoginButton;
