import { IconButton, Tooltip, TooltipProps } from "@mui/material";
import { ReactNode } from "react";

import InfoIcon from "../Icons/Info";
import { BodyTextM } from "../Typography";

export const InfoTooltip = ({
	title,
	sx,
	...rest
}: Partial<Omit<TooltipProps, "title">> & { title: ReactNode }) => {
	return (
		<Tooltip
			sx={{
				padding: 2,
				...sx,
			}}
			title={<BodyTextM>{title}</BodyTextM>}
			leaveTouchDelay={5000}
			enterTouchDelay={0}
			{...rest}
		>
			<IconButton
				disableRipple
				sx={{ minWidth: "18px", height: "max-content", minHeight: "18px" }}
			>
				<InfoIcon />
			</IconButton>
		</Tooltip>
	);
};
