import { AlertColor, Box, ButtonProps, Stack } from "@mui/material";
import Button from "@mui/material/Button";

import Attention from "../AttentionBlock";
import CaretRight from "../Icons/CaretRight";
import { BasicTriggerProps } from ".";

export interface TriggerButtonProps
	extends BasicTriggerProps,
		Omit<ButtonProps, "children" | "onClick" | "type" | "variant"> {
	variant?:
		| "collapsible"
		| "contained"
		| "outlined"
		| "text"
		| "appBarLink"
		| "contactLink"
		| "clear"
		| "cookie-collapsible"
		| "text-light"
		| "action";
}

const TriggerButton = ({
	children,
	onClick,
	variant = "contained",
	...rest
}: TriggerButtonProps): JSX.Element => {
	return (
		<Button
			onClick={onClick}
			component="button"
			variant={variant}
			underline="none"
			disableElevation={true}
			{...rest}
		>
			{children}
		</Button>
	);
};
export default TriggerButton;

interface ITriggerButtonWithAttention extends TriggerButtonProps {
	severity: AlertColor;
}

export const TriggerButtonWithAttention = ({
	children,
	startIcon,
	severity = "success",
	sx,
	...rest
}: ITriggerButtonWithAttention): JSX.Element => {
	return (
		<TriggerButton variant="clear" size="small" sx={sx} {...rest}>
			<Attention icon={startIcon} severity={severity} sx={{ flexGrow: 1 }}>
				<Stack direction="row" alignItems="center">
					<Box sx={{ flexGrow: 1, textAlign: "left" }}>{children}</Box>
					<CaretRight color={severity} sx={{ width: 7, mr: 3, ml: 5 }} />
				</Stack>
			</Attention>
		</TriggerButton>
	);
};

export const TriggerAttentionButton = ({
	children,
	startIcon,
	sx,
	...rest
}: TriggerButtonProps): JSX.Element => {
	return (
		<TriggerButton
			{...rest}
			sx={{
				...sx,
				p: 3,
				textAlign: "left",
				".MuiButton-startIcon": {
					width: 40,
					m: 0,
				},
			}}
			variant="clear"
			size="small"
			startIcon={startIcon}
			endIcon={<CaretRight sx={{ width: 7 }} />}
		>
			<Box component="span" sx={{ flexGrow: 1 }}>
				{children}
			</Box>
		</TriggerButton>
	);
};
