/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp.General
 * GeneralAPIs
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BankIdRegisterPayloadRequest
 */
export interface BankIdRegisterPayloadRequest {
    /**
     * 
     * @type {string}
     * @memberof BankIdRegisterPayloadRequest
     */
    sub: string;
    /**
     * 
     * @type {string}
     * @memberof BankIdRegisterPayloadRequest
     */
    guid: string;
    /**
     * 
     * @type {string}
     * @memberof BankIdRegisterPayloadRequest
     */
    birthNumber: string;
}

/**
 * Check if a given object implements the BankIdRegisterPayloadRequest interface.
 */
export function instanceOfBankIdRegisterPayloadRequest(value: object): value is BankIdRegisterPayloadRequest {
    if (!('sub' in value) || value['sub'] === undefined) return false;
    if (!('guid' in value) || value['guid'] === undefined) return false;
    if (!('birthNumber' in value) || value['birthNumber'] === undefined) return false;
    return true;
}

export function BankIdRegisterPayloadRequestFromJSON(json: any): BankIdRegisterPayloadRequest {
    return BankIdRegisterPayloadRequestFromJSONTyped(json, false);
}

export function BankIdRegisterPayloadRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): BankIdRegisterPayloadRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'sub': json['sub'],
        'guid': json['guid'],
        'birthNumber': json['birthNumber'],
    };
}

export function BankIdRegisterPayloadRequestToJSON(value?: BankIdRegisterPayloadRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'sub': value['sub'],
        'guid': value['guid'],
        'birthNumber': value['birthNumber'],
    };
}

