import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { Form } from "formik";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import {
	MarketingGdprTextWithLink,
	MarketingTextWithPartnerLink,
} from "../../../pages/profile/marketing";
import RadioInput from "../../Form/Input/Radio";
import RadioGroup from "../../Form/Input/Radio/RadioGroup";
import { BodyTextM } from "../../Typography";
import { HeadingM } from "../../Typography";
interface imageProps {
	intro_image: {
		publicURL: string;
	};
}

const useLocalTtranslations = (): imageProps => {
	return useStaticQuery<Queries.AgreementFormImagesQuery>(
		graphql`
			query AgreementFormImages {
				intro_image: file(relativePath: { eq: "1st-step.png" }) {
					childImageSharp {
						gatsbyImageData
					}
				}
				translations: file(name: { eq: "cs" }) {
					childTranslationsJson {
						profile {
							secondStep {
								header
								content
							}
							marketing {
								content
								content2
							}
						}
					}
				}
			}
		`
	);
};

const AgreementForm = (): JSX.Element => {
	const content = useLocalTtranslations();
	const image = getImage(content.intro_image);
	const translations = content.translations.childTranslationsJson.profile;
	return (
		<Form
			noValidate // you need this to turn off browser default validation, thank this, all errors show up at once
		>
			<HeadingM>{translations.secondStep.header}</HeadingM>
			<Stack spacing={5}>
				<BodyTextM>{translations.secondStep.content}</BodyTextM>
				<Stack>
					<GatsbyImage image={image} alt="Intro" style={{ margin: "auto" }} />
				</Stack>
				<Paper
					elevation={16}
					sx={{
						p: 4,
					}}
				>
					<Stack spacing={5}>
						<MarketingTextWithPartnerLink />
						<MarketingGdprTextWithLink />
						<RadioGroup name="agreement">
							<RadioInput
								name="agreement"
								value={true}
								id="agree"
								label="Souhlasím"
							/>
							<RadioInput
								name="agreement"
								value={false}
								id="disagree"
								label="Nesouhlasím"
							/>
						</RadioGroup>
					</Stack>
				</Paper>
			</Stack>
		</Form>
	);
};

export default AgreementForm;
