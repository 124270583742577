/* tslint:disable */
/* eslint-disable */
/**
 * NN.CustomerPortal.ApiApp.General
 * GeneralAPIs
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PaymentInstructions
 */
export interface PaymentInstructions {
    /**
     * 
     * @type {number}
     * @memberof PaymentInstructions
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentInstructions
     */
    bankAccount: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentInstructions
     */
    bankCode: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentInstructions
     */
    variableSymbol: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentInstructions
     */
    specificSymbol?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof PaymentInstructions
     */
    nextDueDate?: Date | null;
}

/**
 * Check if a given object implements the PaymentInstructions interface.
 */
export function instanceOfPaymentInstructions(value: object): value is PaymentInstructions {
    if (!('amount' in value) || value['amount'] === undefined) return false;
    if (!('bankAccount' in value) || value['bankAccount'] === undefined) return false;
    if (!('bankCode' in value) || value['bankCode'] === undefined) return false;
    if (!('variableSymbol' in value) || value['variableSymbol'] === undefined) return false;
    return true;
}

export function PaymentInstructionsFromJSON(json: any): PaymentInstructions {
    return PaymentInstructionsFromJSONTyped(json, false);
}

export function PaymentInstructionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentInstructions {
    if (json == null) {
        return json;
    }
    return {
        
        'amount': json['amount'],
        'bankAccount': json['bankAccount'],
        'bankCode': json['bankCode'],
        'variableSymbol': json['variableSymbol'],
        'specificSymbol': json['specificSymbol'] == null ? undefined : json['specificSymbol'],
        'nextDueDate': json['nextDueDate'] == null ? undefined : (new Date(json['nextDueDate'])),
    };
}

export function PaymentInstructionsToJSON(value?: PaymentInstructions | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'amount': value['amount'],
        'bankAccount': value['bankAccount'],
        'bankCode': value['bankCode'],
        'variableSymbol': value['variableSymbol'],
        'specificSymbol': value['specificSymbol'],
        'nextDueDate': value['nextDueDate'] == null ? undefined : ((value['nextDueDate'] as any).toISOString()),
    };
}

