import { EventMessage, EventType, InteractionType } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { useQueryClient } from "@tanstack/react-query";
import { navigate } from "gatsby";
import { useEffect, useState } from "react";

import { ADB2C_ERROR_CODES } from "../../adb2c-constants";
import { setActivity } from "../Logout/utils";

const defaultRequest = {
	scopes: ["openid", process.env.GATSBY_B2C_API_SCOPE],
};

export const useLoginError = () => {
	const [loginError, setLoginError] = useState();
	const [authority, setAuthority] = useState();

	const { instance } = useMsal();
	const queryClient = useQueryClient();

	useEffect(() => {
		const authority = localStorage.getItem("authority");
		if (authority) setAuthority(authority);
	}, []);

	useEffect(() => {
		const callbackId = instance.addEventCallback(
			async (event: EventMessage) => {
				if (
					[
						EventType.LOGIN_FAILURE,
						EventType.LOGOUT_FAILURE,
						EventType.SSO_SILENT_FAILURE,
						EventType.ACQUIRE_TOKEN_FAILURE,
						EventType.ACQUIRE_TOKEN_BY_CODE_FAILURE,
					].includes(event.eventType)
				) {
					if (
						event.error &&
						event.error.errorMessage.indexOf(
							ADB2C_ERROR_CODES.USER_FORGOT_PASSWORD
						) > -1
					) {
						if (event.interactionType === InteractionType.Redirect) {
							const request = {
								authority: `https://${process.env.GATSBY_B2C_DOMAIN}/${process.env.GATSBY_B2C_TENANT_ID}/${process.env.GATSBY_B2C_SIGNUP_RESET_PASSWORD_POLICY}`,
							};
							await instance.loginRedirect({ ...defaultRequest, ...request });
						}
						return;
					}

					if (
						event.error &&
						event.error.errorMessage.indexOf(
							ADB2C_ERROR_CODES.USER_CANCELED_OPERATION
						) > -1
					) {
						return;
					}

					setLoginError(event.error);
				}

				if (
					[
						EventType.LOGIN_SUCCESS,
						EventType.ACQUIRE_TOKEN_SUCCESS,
						EventType.ACQUIRE_TOKEN_BY_CODE_SUCCESS,
						EventType.SSO_SILENT_SUCCESS,
					].includes(event.eventType)
				) {
					localStorage.removeItem("REACT_QUERY_OFFLINE_CACHE");
					queryClient.removeQueries();
					setActivity();
					instance.setActiveAccount(event.payload.account);

					if (
						!event.payload.account.idTokenClaims.extension_PID ||
						!event.payload.account.idTokenClaims
							.extension_UserDetailInfoEncrypted
					) {
						await instance.logout({
							postLogoutRedirectUri: "/uzivatel-nenalezen/",
						});
					} else if (
						event.payload.account.idTokenClaims.partner_code === "AirBank"
					) {
						void navigate("/uvitaci-obrazovka-airbank/");
					} else {
						if (
							event.payload.account.idTokenClaims
								.extension_UserDetailInfoEncrypted === undefined
						) {
							await instance.logout({
								postLogoutRedirectUri: "/prihlaseni/",
							});
						} else {
							void navigate("/smlouvy/");
						}
					}
				}
			}
		);

		return () => {
			if (callbackId) {
				instance.removeEventCallback(callbackId);
			}
		};
	}, [instance, queryClient]);

	return {
		loginError,
		authority,
	};
};
