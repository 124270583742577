import { Box, Skeleton, Stack } from "@mui/material";
import useIsDesktop from "desktop/useIsDesktop";

import { GetLifeDetailV2Response, ProductType } from "../../../../models";
import { contractLink, NumberToCZK } from "../../../functions";
import { useLifeDetail } from "../../../queryHooks";
import cs from "../../../translations/cs.json";
import CaretLeft from "../../Icons/CaretLeft";
import CaretRight from "../../Icons/CaretRight";
import { BodyTextS, HeadingM } from "../../Typography";
import { formatFufiTitle } from "../../ZivotniPojisteni/FufiPage/utils";
import { findFufiFirstPerson } from "../../ZivotniPojisteni/Heading/Subtitle";
import { DetailLink } from "../ContractCardDesktopMenu";
import { CarretWrapper, contractTypeToTitle } from ".";

export type ContractHeaderProps = {
	title?: string;
	subtitle?: string;
	personDescription?: string | null;
	link?: string;
	amount?: string;
};

export const ContractCardHeaderView = ({
	amount,
	link,
	personDescription,
	subtitle,
	title,
}: ContractHeaderProps) => {
	const isDesktop = useIsDesktop();

	const personDescriptionElement = (() => {
		if (personDescription === null) return null;
		return (
			<BodyTextS sx={{ mb: 0, whiteSpace: "pre-wrap" }}>
				{personDescription ?? <Skeleton />}
			</BodyTextS>
		);
	})();

	return (
		<Stack
			data-test="contract"
			sx={{
				flexGrow: 1,
				height: "100%",
			}}
		>
			<Stack
				direction="row"
				justifyContent="space-between"
				alignItems="center"
				gap={4}
			>
				<HeadingM sx={{ flexGrow: 1, mb: 0, pr: 4 }}>
					{title ?? <Skeleton />}
				</HeadingM>
			</Stack>
			<BodyTextS sx={{ mb: 4 }} light>
				{subtitle ? subtitle : <Skeleton />}
			</BodyTextS>
			{personDescriptionElement}
			{amount ? <BodyTextS sx={{ mb: 0 }}>{amount}</BodyTextS> : <Skeleton />}
			{!isDesktop && (
				<Box sx={{ mt: "auto" }}>
					<DetailLink link={link} />
				</Box>
			)}
		</Stack>
	);
};

interface IContractHeaderWithPensionData {
	contractId: string;
}

export const ContractHeaderWithFufiData = ({
	contractId,
}: IContractHeaderWithPensionData) => {
	const isDesktop = useIsDesktop();
	const { data, isSuccess } = useLifeDetail<GetLifeDetailV2Response>({
		id: contractId,
		contractType: ProductType.Clf,
	});

	const title = (() => {
		if (!isDesktop) return contractTypeToTitle[ProductType.Clf];
		if (!data?.productDescription) return "";
		return formatFufiTitle(data.productDescription);
	})();

	const subtitle = (() => {
		if (!isSuccess) return undefined;
		if (isDesktop)
			return `${cs.life.global.contractNumber} ${data.proposalNumber}`;
		if (data.productDescription)
			return `${data.productDescription} ${data.proposalNumber}`;
		return `${cs.life.global.contractNumber} ${data.proposalNumber}`;
	})();

	const personDescription = (() => {
		if (!isSuccess) return undefined;
		const firstPerson = findFufiFirstPerson(data?.insuredPersons);
		if (!firstPerson) return null;

		return `${cs.life.global.owner}: ${firstPerson.titlePrefix} ${firstPerson.firstName} ${firstPerson?.lastName} ${firstPerson.titleSuffix}`;
	})();

	const amount = (() => {
		if (!isSuccess) return;
		return `${cs.frequencies[data.paymentFrequency]} ${
			cs.global.dueInsurance
		}: ${NumberToCZK(data.premiumClient)}`;
	})();

	return (
		<ContractCardHeaderView
			title={title ?? undefined}
			subtitle={subtitle}
			personDescription={personDescription}
			amount={amount}
			link={contractLink(contractId, ProductType.Clf)}
		/>
	);
};

const carretStyles = {
	width: "0.8rem",
	height: "1.3rem",
	color: "primary.main",
};

interface IContractCardCarrets {
	isFirst: boolean;
	isLast: boolean;
	onShift: (modifier: number) => void;
	children: JSX.Element;
}

export const ContractHeaderMobileCarrets = ({
	isLast,
	isFirst,
	onShift,
	children,
}: IContractCardCarrets) => {
	return (
		<Stack
			direction={"row"}
			gap={1}
			sx={{ flexDirection: "row", alignItems: "center", width: "100%", py: 0 }}
		>
			<CarretWrapper onClick={() => onShift(-1)}>
				<CaretLeft
					sx={{ ...carretStyles, visibility: isFirst ? "hidden" : "initial" }}
				/>
			</CarretWrapper>
			{children}
			<CarretWrapper onClick={() => onShift(1)}>
				<CaretRight
					sx={{ ...carretStyles, visibility: isLast ? "hidden" : "initial" }}
				/>
			</CarretWrapper>
		</Stack>
	);
};
